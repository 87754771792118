.centralizedHeading {
  display: grid;
  justify-self: center;
  justify-items: center;
  max-width: 689px;
  text-align: center;
  gap: clamp(20px, calc(30vw/7.68), 30px);
  .description {
    margin-top: -10px;
  }
  @media (max-width: 999px) {
    margin-top: unset;
  }
}