.faqWrapper {
  background: var(--background);
  padding: 75px 0px 50px;

  .faq {
    display: grid;
    grid-template-columns: 1fr;

    header {
      max-width: 689px;
      display: grid;
      row-gap: 14px;

      .description {
        margin-top: 2px;
      }

      .heading {
        &:before {
          background: var(--background);
        }
      }
    }

    row-gap: 64px;

    .faqList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      row-gap: 30px;
      align-items: start;
      margin-bottom: 11px;

      .image {
        height: auto;
        width: 100%;
        display: block;
        object-fit: contain;
        position: sticky;
        top: -16px;
      }

      .items {
        display: grid;
        row-gap: 30px;

        .item {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-areas: "button" "description";
          background: var(--neutral-100);
          padding: 15px 25px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;

            .button {
              .svgWrapper {
                >svg {
                  >circle {
                    stroke-dasharray: 118;
                  }
                }
              }
            }
          }

          &:has(summary:focus-visible) {
            outline: 1px solid var(--coffee-500);
          }

          .button {
            display: grid;
            column-gap: 10px;
            grid-template-columns: auto 1fr auto;
            align-items: flex-start;
            text-align: start;
            grid-template-areas: "image title icon";

            &:focus-visible {
              outline: none;
            }


            .image {
              grid-area: image;
              height: clamp(30px, calc(52vw/7.68), 52px);
              width: clamp(30px, calc(52vw/7.68), 52px);
              margin-top: -10px;
              align-self: start;
            }


            .heading {
              >h3 {
                font-size: clamp(calc(16rem/16), calc(24vw/7.68), calc(24rem/16));
              }
            }

            .svgWrapper {
              border-left: 1px solid var(--dark-neutral-100);
              padding-left: 10px;
              height: clamp(35px, calc(45vw/7.68), 45px);
              width: clamp(35px, calc(45vw/7.68), 45px);
              align-self: center;

              >svg {
                circle {
                  stroke-dasharray: 106;
                  transition: stroke-dasharray 0.5s ease-in-out;
                }

                transition: transform 0.3s ease-in-out;
              }
            }
          }

          &[open] {
            .button {
              .svgWrapper {
                >svg {
                  transform: rotate(180deg);
                }
              }
            }
          }

          .description {
            grid-area: description;
            display: grid;
            gap: 10px;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            transition: opacity 0.3s ease-in-out;
          }

          &[open] {
            .description {
              margin-top: 10px;
              visibility: visible;
              max-height: 100%;
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: 1349px) {
      .faqList {
        grid-template-columns: 1fr;

        .image {
          position: relative;
          aspect-ratio: 16/9;
          object-fit: cover;
          border-radius: 16px;
        }

        .items {
          .item {
            .image {
              align-self: center;
              aspect-ratio: unset;
              position: unset;
            }
          }
        }
      }
    }
  }
}