.button {
  color: var(--coffee-500);
  text-decoration-line: underline;
  display: flex;
  gap: 5px;
  text-align: start;

  >svg {
    align-self: center;
  }

  &.primary {
    text-decoration: none;
    padding: 8px 94px;
    text-wrap: nowrap;
    color: var(--midnight-green-500);
    width: fit-content;
    border-radius: 16px;
    font-size: clamp(calc(20rem/16), calc(20vw/7.68), calc(24rem/16));
    border: 2px solid var(--midnight-green-500);

    &:focus-visible {
      outline-offset: 7px;
      outline: 1px solid var(--midnight-green-500);
    }

    &:hover {
      >span {
        background: var(--gradient);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

  }

  &.borderless {
    text-decoration: underline;
    &:focus-visible {
      outline-offset: 7px;
      outline: 1px solid var(--coffee-500);
      border-radius: 16px;
    }
    &:hover {
      color: var(--coffee-200);
    }
  }

  &.secondary {
    text-decoration: none;
    padding: 10px 96px;
    text-wrap: nowrap;
    color: var(--neutral-100);
    background-color: var(--midnight-green-500);
    width: fit-content;
    border-radius: 16px;
    font-size: clamp(calc(20rem/16), calc(20vw/7.68), calc(24rem/16));

    &:focus-visible {
      outline-offset: 7px;
      outline: 1px solid var(--midnight-green-500);
    }

    &:hover {
      background: var(--gradient);
    }
  }

  @media(max-width: 499px) {
    &.secondary,
    &.primary {
      padding: 10px 0px;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}