.checkbox {
  display: grid;
  width: fit-content;
  padding: 0px 10px;

  .checkboxWrapper {
    position: relative;
    gap: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;

    .icon {
      width: clamp(34px, calc(36vw / 7.68), 36px);
      height: clamp(34px, calc(36vw / 7.68), 36px);
      border: 1px solid #000;
      border-radius: 5px;
      position: relative;
      display: grid;
      place-items: center;

      svg {
        position: absolute;

        path {
          stroke-dasharray: 27;
          stroke-dashoffset: 27;
          transition: stroke-dashoffset 0.3s ease-in-out;
        }
      }

      &:has(input:checked) {
        svg {
          path {
            stroke-dashoffset: 0;
          }
        }
      }
    }

    p {
      display: flex;
      gap: 7px;
    }

    &:has(input:focus-visible) {
      outline: 1px solid #000;
      outline-offset: 10px;
      border-radius: 16px;
    }
  }

  &:has(.error) {
    .icon {
      border: 1px solid var(--error-500);
    }

    p {
      color: var(--error-500);
    }

    .error {
      color: var(--error-500);
    }
  }
}